<template>
  <div class="edit-project-form">
    <fd-form @submit="submitForm">
      <slot></slot>

      <!-- Update Button -->
      <div class="text-right p-2">
        <fd-button
          type="submit"
          class="main medium fixed round"
          :disabled="isLoading"
        >
          <i class="fas fa-pen mr-1"></i>
          Update
        </fd-button>
      </div>
    </fd-form>
  </div>
</template>

<script>
import ProjectModel from "@/modules/Project/models/ProjectModel";

export default {
  components: {},
  mixins: [],
  props: {
    projectId: {
      type: [Number, String],
      required: true
    },
    project: {
      type: Object,
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: "developer",
      validator: (val) => ["developer", "agency"].includes(val)
    },
    updateProjectAPI: {
      type: Function
    }
  },
  data: function () {
    return {};
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {
    setIsLoading(bool) {
      this.$store.commit("setIsLoading", bool);
      this.$emit("update:isLoading", bool);
    },

    async submitForm() {
      try {
        this.setIsLoading(true);

        await this.updateProjectAPI({
          id: this.projectId,
          payload: new ProjectModel(this.project, {
            isDeveloper: this.type === "developer",
            isAgency: this.type === "agency"
          }).toEditPayload()
        });
        this.setIsLoading(false);
        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "Project is updated successfully."
        });
        this.$router.push({
          name: "ManageProjectsDetails",
          id: this.projectId
        });
      } catch (error) {
        this.setIsLoading(false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to update the project detail."
        });
      }
    }
  }
};
</script>

<style lang="scss"></style>
